exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abouts-tsx": () => import("./../../../src/pages/abouts.tsx" /* webpackChunkName: "component---src-pages-abouts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-list-tsx": () => import("./../../../src/templates/articles-list.tsx" /* webpackChunkName: "component---src-templates-articles-list-tsx" */),
  "component---src-templates-artist-discography-tsx": () => import("./../../../src/templates/artist/discography.tsx" /* webpackChunkName: "component---src-templates-artist-discography-tsx" */),
  "component---src-templates-artist-index-tsx": () => import("./../../../src/templates/artist/index.tsx" /* webpackChunkName: "component---src-templates-artist-index-tsx" */),
  "component---src-templates-artist-songs-tsx": () => import("./../../../src/templates/artist/songs.tsx" /* webpackChunkName: "component---src-templates-artist-songs-tsx" */),
  "component---src-templates-artists-list-tsx": () => import("./../../../src/templates/artists-list.tsx" /* webpackChunkName: "component---src-templates-artists-list-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-discography-tsx": () => import("./../../../src/templates/discography.tsx" /* webpackChunkName: "component---src-templates-discography-tsx" */),
  "component---src-templates-disk-tsx": () => import("./../../../src/templates/disk.tsx" /* webpackChunkName: "component---src-templates-disk-tsx" */),
  "component---src-templates-interview-tsx": () => import("./../../../src/templates/interview.tsx" /* webpackChunkName: "component---src-templates-interview-tsx" */),
  "component---src-templates-interviews-list-tsx": () => import("./../../../src/templates/interviews-list.tsx" /* webpackChunkName: "component---src-templates-interviews-list-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-song-tsx": () => import("./../../../src/templates/song.tsx" /* webpackChunkName: "component---src-templates-song-tsx" */),
  "component---src-templates-songs-list-tsx": () => import("./../../../src/templates/songs-list.tsx" /* webpackChunkName: "component---src-templates-songs-list-tsx" */),
  "component---src-templates-user-articles-tsx": () => import("./../../../src/templates/user/articles.tsx" /* webpackChunkName: "component---src-templates-user-articles-tsx" */),
  "component---src-templates-user-index-tsx": () => import("./../../../src/templates/user/index.tsx" /* webpackChunkName: "component---src-templates-user-index-tsx" */),
  "component---src-templates-user-translations-tsx": () => import("./../../../src/templates/user/translations.tsx" /* webpackChunkName: "component---src-templates-user-translations-tsx" */),
  "component---src-templates-users-list-tsx": () => import("./../../../src/templates/users-list.tsx" /* webpackChunkName: "component---src-templates-users-list-tsx" */)
}

